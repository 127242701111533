import Vue from "vue";
import "./plugins/bootstrap-vue";
const VueMasonryPlugin = require("vue-masonry").VueMasonryPlugin;

import App from "./App.vue";
import router from "./router";
import store from "./store";
import "./app.scss";

import LazyImg from "./components/LazyImg.vue";
import Service from "./components/Service.vue";

Vue.use( VueMasonryPlugin );
Vue.component("LazyImg", LazyImg);
Vue.component("Service", Service);

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#krave");
