import Vue from "vue";
import Vuex from "vuex";
import axios from "axios";

/*
import WORKS from "@//assets/json/works.json";
import SERVICES from "@//assets/json/services.json";
import ARTISTS from "@//assets/json/artists.json";
import BRANDS from "@//assets/json/brands.json";
import LABELS from "@//assets/json/labels.json";
import CLIENT_IMAGES from "@//assets/json/clientImages.json";
import TEAM_MEMBERS from "@//assets/json/teamMembers.json";
*/

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    loadingStatus: false,
    footer: true,
    works: [],
    work: {},
    workId: null,
    services: [],
    artists: [],
    brands: [],
    labels: [],
    clientImages: [],
    teamMembers: [],
    tags: []
  },
  mutations: {
    SET_WORKS( state, works) {
      state.works = works;
    },
    SET_WORK_ID( state, value) {
      state.workId = value;
    },
    SET_WORK(state, value) {
      state.work = value;
    },
    SET_SERVICE(state, services) {
      state.services = services;
    },
    SET_ARTISTS(state, artists) {
      state.artists = artists;
    },
    SET_BRANDS(state, brands) {
      state.brands = brands;
    },
    SET_LABELS(state, labels) {
      state.labels = labels;
    },
    SET_CLIENT_IMAGES(state, clientImages) {
      state.clientImages = clientImages;
    },
    SET_FOOTER(state, value) {
      state.footer = value;
    },
    SET_TAGS(state, value) {
      state.tags = value;
    },
    SET_TEAM_MEMBERS(state, value) {
      state.teamMembers = value;
    }
  },
  getters: {
    works: state => {
      return state.works;
    },
    footer: ( state ) => {
      return state.footer;
    },
    tags: ( state ) => {
      return state.tags;
    },
    teamMembers: ( state ) => {
      return state.teamMembers;
    },
    getTags: (state) => (tags: string) => {
      if (tags === "all") {
        if ( !state.works ) {
          return false;
        } else {
          return state.works;
        }
      }
      if ( !state.works ) {
        return false;
      } else {
        return state.works.filter((res: any) => {
          if (res.tags.toString().indexOf(tags) !== -1) {
            return res;
          }
        });
      }
    },
    getWork: ( state ) => (tags: string) => {
      // console.log( 'state works: ', state.works );
      if ( !state.works.length ) {
        // console.log( 'no works defined!', state.works );
      }
      return state.works.filter( ( res: any ) => {
        if ( res.index.toString() === tags.toString() ) {
          return res;
        }
      });
    },
    getWorkById: ( state ) => ( id: number) => {
      if ( !state.works || !state.workId ) {
        return false;
      } else {
        state.work = state.works[id];
      }
    }
  },
  actions: {
    setTags( { commit, dispatch } ) {
      dispatch('getTagsList').then( tags => commit('SET_TAGS', tags ) );
      return true;
    },
    getTagsList: ( { state } ) => {
      const thisTags = ["all"];
      if ( !state.works ) {
        return false;
      } else {
        state.works.map((res: any) => {
          res.tags.forEach((elem: any) => {
            if (!thisTags.includes(elem)) {
              thisTags.push(elem);
            }
          });
        });
        return thisTags;
      }
    },
    getClientImages( { commit } ) {
      return axios.get('/assets/json/clientImages.json').then(
          (response: any) => {
            commit( 'SET_CLIENT_IMAGES', response.data[0].clientImages );
          })
    },
    getWorks( { commit } ) {
      // console.log( 'getWorks started!');
      return axios.get('/assets/json/works.json').then(
          (response: any) => {
            // console.log( 'getWorks response: ', response );
            commit( 'SET_WORKS', response.data[0].works );
          });
    },
    getArtists( { commit } ) {
      return axios.get('/assets/json/artists.json').then(
          (response: any) => {
            commit( 'SET_ARTISTS', response.data[0].artists );
          });
    },
    getBrands( { commit } ) {
      return axios.get('/assets/json/brands.json').then(
          (response: any) => {
            commit( 'SET_BRANDS', response.data[0].brands );
          });
    },
    getLabels( { commit } ) {
      return axios.get('/assets/json/labels.json').then(
          (response: any) => {
            commit( 'SET_LABELS', response.data[0].labels );
          });
    },
    getServices( { commit } ){
      return axios.get('/assets/json/services.json').then(
          (response: any) => {
            commit( 'SET_SERVICE', response.data[0].services );
          });
    },
    getTeamMembers( { commit } ){
      return axios.get('/assets/json/teamMembers.json').then(
          (response: any) => {
            commit( 'SET_TEAM_MEMBERS', response.data[0].teamMembers );
          });
    }
  },
  modules: {}
});
