<template>
  <div id="app">
    <NavBar></NavBar>
    <router-view v-if="started" />
  </div>
</template>

<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

#nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
</style>
<script>
import NavBar from "@/components/NavBar";

export default {
  components: { NavBar },
  data() { return { started: false } },
  async created() {
    await this.$store.dispatch('getWorks' ).then( resp => this.started = true );
  }
};
</script>
