import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";
import { LayoutPlugin } from "bootstrap-vue";

Vue.use(VueRouter);
Vue.use(LayoutPlugin);

const routes: Array<RouteConfig> = [
  {
    path: "/",
    redirect: "home",
  },
  {
    path: "/home/:work?",
    name: "Home",
    component: () => import('../views/Home.vue'),
    props: true,
  },
  {
    path: "/services",
    name: "Services",
    component: () => import("../views/Services.vue"),
  },
  {
    path: "/clients",
    name: "Clients",
    component: () => import("../views/Clients.vue"),
  },
  {
    path: "/teams/:name?",
    name: "Teams",
    component: () => import("../views/Teams.vue"),
  },
  {
    path: "/partners",
    name: "Partners",
    component: () => import("../views/Partners.vue"),
  },
  {
    path: "/contact",
    name: "Contact",
    component: () => import("../views/Contact.vue"),
  },
  {
    path: "/aboutus",
    name: "About",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import( "../views/About.vue" ),
  },
  {
    path: "*",
    name: "404",
    component: () => import("../views/404.vue"),
  },
];

const router = new VueRouter({
  mode: "history",
  routes,
});

export default router;
