<template>
  <img :src="item.smImg" loading="lazy" class="img-fluid" :alt="item.txtInfo" />
</template>
<style scoped></style>
<script>
export default {
  name: "LazyImg",
  props: {
    item: Object,
  },
  computed: {
    dataUrl() {
      const { width, height } = this.$attrs;
      if (!width || !height) return "";
      // console.log("this attrs:", this.$attrs);

      // create a tiny png with matching aspect ratio as img
      const w = 100;
      const canvas = document.createElement("canvas");
      canvas.width = w;
      canvas.height = (height / width) * w;

      return canvas.toDataURL();
    },
  },
};
</script>
