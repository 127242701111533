<template>
  <div id="navBar" class="bg-light sticky-top">
    <div class="navBarNav">
      <b-navbar toggleable="lg" type="light" variant="light">
        <b-navbar-brand to="/">
          <img
            src="../assets/logo.png"
            class="img-fluid kraveStudiosLogo"
            id="kraveStudiosLogo"
            alt="KraveStudios"
          />
        </b-navbar-brand>
        <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>
        <b-collapse id="nav-collapse" is-nav>
          <b-navbar-nav class="ml-auto">
            <b-nav-item class="nav-item" to="/"> Work </b-nav-item>
            <b-nav-item class="nav-item" to="/services"> Services </b-nav-item>
            <b-nav-item class="nav-item" to="/clients"> Clients </b-nav-item>
            <b-nav-item-dropdown no-caret role="menu" left>
              <template slot="button-content"
                ><router-link class="teams-main" v-slot="{ team, href }" to="/teams"
                  >Teams</router-link
                ></template
              >
              <b-nav-item to="/teams/ray-kay"> Ray Kay </b-nav-item>
              <b-nav-item to="/teams/michael-raveney">
                Michael Raveney
              </b-nav-item>
              <b-nav-item to="/teams/vitaly-reznik"> Vitaly Reznik </b-nav-item>
            </b-nav-item-dropdown>
            <b-nav-item
              class="nav-item"
              href="https://www.faktory.nyc/"
              target="_blank"
            >
              Fashion production
            </b-nav-item>
            <b-nav-item class="nav-item" to="/aboutus"> About Us </b-nav-item>
            <b-nav-item class="nav-item" to="/partners">
              Strategic Partners
            </b-nav-item>
            <b-nav-item class="nav-item" to="/contact"> Contact </b-nav-item>
          </b-navbar-nav>
        </b-collapse>
      </b-navbar>
    </div>
  </div>
</template>
<script>
export default {
  methods: {
    goToTeams: () => {
      // console.log("going...");
      this.$router.push("/teams");
    },
  },
};
</script>
<style type="text/css">
@import "../css/NavBar.scss";
</style>
