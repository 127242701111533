<template>
  <div class="col-12 col-sm-6 col-md-4">
    <h4 class="cardTitle p-4">{{ service.title }}</h4>
    <p class="cardText px-4 pb-3">{{ service.text }}</p>
  </div>
</template>

<script>
export default {
  name: "Service",
  created() {
    this.$store.commit("SET_FOOTER", true);
  },
  props: {
    service: Object,
  },
};
</script>

<style scoped>
@import "../css/Service.scss";
</style>
